





























































































































import {Component, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterBanner from '@/components/filters/FilterBanner.vue'
import {Banner} from '@/model/resource/Banner'
import {BannerCollection} from '@/model/collection/BannerCollection'
import {ListBannerSchema} from '@/schema/resource/Banner/ListBannerSchema'

@Component({
  components: {FilterToggle, FilterBanner},
})
export default class ListBannerView extends Mixins(MixinRouteMatch) {
  schema = new ListBannerSchema()
  collection = new BannerCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    this.collection.setOrderBy('order')
    await this.populate()
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: Banner) {
    this.$nav.pushByName('editBanner', item.$id)
  }

  async removeItem(item: Banner) {
    await this.$dialog.remove(item)
    await item.removeBanner()
    await this.collection.queryAsPage()
  }

  async changeOrder(item: Banner, increment: number) {
    if (item.order === null) item.order = 0
    item.order += increment
    await item.changeOrder()
    this.$toast.success('system.success.persist')
    await this.populate()
  }

  async populate() {
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }
}
