/**
 * Filter Schema of Banner
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IBannerCollectionResourcesHolder} from '@/model/collection/BannerCollection'
import {Banner} from '@/model/resource/Banner'
import {InputDateTime} from '@/components/inputs/InputDateTime'

/* TODO: review generated schema */
export class FilterBannerSchema extends DefaultSchema
  implements IBannerCollectionResourcesHolder {
  readonly name = 'FilterBanner'

  readonly fieldSet: FieldSet<Banner> = {
    startVisibleStartDatetime: (schema): FieldComponent => ({
      is: InputDateTime,
      bind: {
        type: 'mask',
        maskPreset: 'datetime',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    endVisibleStartDatetime: (schema): FieldComponent => ({
      is: InputDateTime,
      bind: {
        type: 'mask',
        maskPreset: 'datetime',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    startVisibleEndDatetime: (schema): FieldComponent => ({
      is: InputDateTime,
      bind: {
        type: 'mask',
        maskPreset: 'datetime',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    endVisibleEndDatetime: (schema): FieldComponent => ({
      is: InputDateTime,
      bind: {
        type: 'mask',
        maskPreset: 'datetime',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    minOrder: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
    maxOrder: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'number',
        label: this.translateFrom(schema.fieldName),
      },
    }),
  }
}
