
















import {Component, Prop, Watch, Vue} from 'vue-property-decorator'
import {BannerCollection} from '@/model/collection/BannerCollection'
import {FilterBannerSchema} from '@/schema/resource/Banner/FilterBannerSchema'

@Component
export default class FilterBanner extends Vue {
  @Prop({type: Object, required: true}) collection!: BannerCollection

  schema = new FilterBannerSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = []

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryAsPage())
  }
}
